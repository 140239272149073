import React, { useEffect, useState } from "react";

import { OktaError, logout, useCustomerAuth } from "../../../core/auth";
import { LoadingMessage } from "../../../components";

const LoginCallback = () => {
  const [callbackError, setCallbackError] = useState(null);
  const { oktaAuth, authState } = useCustomerAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        oktaAuth.start();
      } catch (ex) {
        setCallbackError(ex);
        await logout();
      }
    };
    fetchData();
  }, [oktaAuth]);

  const authError = authState?.error;

  const displayError = callbackError || authError;
  if (displayError) {
    return <OktaError error={displayError} />;
  }

  return <LoadingMessage message="Setting up your profile ..." />;
};

export default LoginCallback;
